@font-face {
  font-family: ProximaNova;
  src: url("/src/fonts/Proxima Nova Regular.ttf") format("truetype");
  font-family: ProximaNova-Bold;
  src: url("/src/fonts/Proxima Nova Bold.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.buttonsContainer {
  justify-content: center;
  margin-top: 5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}
.buttonBox {
  padding: 20px;
  border-radius: 10px;
  color: #162857;
  display: flex;
  align-items: center;
}
.buttonBox:hover {
  background-color: #c7f1f1;
}

.intro {
  font-family: "ProximaNova", "Helvetica Neue";
  background-image: url("/src/images/home-bg.png");
  /* height: 100%;  */
  width: 100vw;
  height: 100vh;
  left: 0;

  /* Center and scale the image nicely */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: block;
  object-fit: cover;
}

.introtext {
  position: relative;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}

.title {
  margin: 10;
}

.Aggie {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 7rem;
  color: #162857;
  margin: 0;
}

.Explorer {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 7rem;
  color: #eab517;
  margin: 0;
}

.Description {
  font-family: "ProximaNova", "Helvetica Neue";
  padding-top: 0.8rem;
  font-size: 2rem;
  color: #eab517;
  margin: 20;
}

.button {
  padding: 2rem;
  outline-color: #162857;
}

#homebutton {
  font-family: "ProximaNova", "Helvetica Neue";
  background-color: #162857;
  font-size: 1.8rem;
  color: #ffffff;
  outline-color: #162857;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-right: 5rem;
  padding-left: 5rem;
  border-radius: 1rem;
}

.introarrow {
  position: absolute;
  width: 100%;
  bottom: 70px;
  text-align: center;
}

.whatwedo {
  /*margin-top: 60rem;*/
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
  color: #162857;
  text-align: center;
  margin: 0;
}

.arrow {
  display: inline-block;
  color: #162857;
  width: 1.2rem;
  height: 1.2rem;
  border: 2.5px solid transparent;
  border-right-color: #162857;
  border-bottom-color: #162857;
  transform: rotate(45deg);
}

.mission {
  width: 100%;
  height: 400px;
}

#mission2 {
  display: none;
}

#missionleft {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  color: #162857;
  text-align: center;
  float: left;
  width: 40%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#missionright {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 18px;
  color: #5a5a5a;
  float: right;
  width: 60%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  line-height: 3.7rem;
  padding-right: 190px;
}

.graph {
  justify-content: "center";
}

.features {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ebf8f8;
  width: 100%;
}

.features2 {
  display: none;
}

#featuresleft {
  padding-top: 50px;
  padding-left: 60px;
}

.cards {
  padding: 60px;
  border-radius: 1rem;
  background-color: white;
}

#featuresright {
  padding-top: 50px;
  padding-left: 65px;
}

#featuresSection {
  margin-top: 20px;
  justify-content: center;
}

#gradecardimage {
  text-align: center;
  margin-left: -20px;
  padding-top: 35px;
  padding-bottom: 20px;
}

#gradeimage {
  width: 30vw;
  height: 20vw;
}

#gradecardtitle {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 3rem;
  color: #5a5a5a;
  padding-left: 4.5rem;
}

#gradecarddescription {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.7rem;
  color: #5a5a5a;
  padding-top: 1rem;
  padding-left: 4.5rem;
  padding-right: 4rem;
  line-height: 3rem;
}

#enrollmentcardimage {
  text-align: center;
  margin-left: -20px;
  padding-top: 48px;
  padding-bottom: 20px;
}

#enrollmentimage {
  width: 380px;
  height: auto;
}

#enrollmentcardtitle {
  font-family: "ProximaNova", "Helvetica Neue";
  color: #5a5a5a;
  font-size: 3rem;
  padding-top: 0.8rem;
  padding-left: 5.5rem;
}

#enrollmentcarddescription {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.7rem;
  color: #5a5a5a;
  padding-top: 1rem;
  padding-left: 5.5rem;
  padding-right: 5.5rem;
  line-height: 3rem;
}

.footer {
  width: 100%;
  height: 125px;
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 2rem;
  color: #4f7094;
  text-align: center;
  padding-top: 55px;
}

.footer2 {
  display: none;
}

.footertext {
  color: #4f7094;
}

.footerCatalog {
  padding-right: 20rem;
}

.footerGrades {
  padding-right: 20rem;
}
.footerEnrollment {
  padding-right: 20rem;
}

.footerAbout {
}

@media screen and (max-width: 1100px) {
  .intro {
    font-family: "ProximaNova", "Helvetica Neue";
    background-image: url("/src/images/home-bg.png");
    /* height: 100%;  */
    width: 100vw;
    height: 100vh;

    /* Center and scale the image nicely */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: block;
    object-fit: cover;
    margin: 0 auto;
  }

  .introtext {
    position: relative;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }

  .title {
    margin: 10;
  }

  .Aggie {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 4.2rem;
    color: #162857;
    margin: 0;
  }
  .Explorer {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 4.2rem;
    color: #eab517;
    margin: 0;
  }
  .Description {
    font-family: "ProximaNova", "Helvetica Neue";
    padding-top: 0.8rem;
    font-size: 2rem;
    color: #eab517;
    margin: 20;
  }
  .button {
    padding: 2rem;
    outline-color: #162857;
  }
  a#homebutton {
    font-family: "ProximaNova", "Helvetica Neue";
    background-color: #162857;
    font-size: 1.5rem;
    color: #ffffff;
    outline-color: #162857;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 3rem;
    padding-left: 3rem;
    border-radius: 1rem;
  }

  .introarrow {
    position: absolute;
    width: 100%;
    bottom: 60px;
    text-align: center;
  }
  .whatwedo {
    /*margin-top: 60rem;*/
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.5rem;
    color: #162857;
    text-align: center;
    margin: 0;
  }

  .arrow {
    display: inline-block;
    color: #162857;
    width: 1rem;
    height: 1rem;
    border: 2.5px solid transparent;
    border-right-color: #162857;
    border-bottom-color: #162857;
    transform: rotate(45deg);
  }
  .mission {
    width: 100%;
    height: 300px;
  }
  #missionleft {
    display: none;
  }

  #missionright {
    display: none;
  }

  #mission2 {
    display: block;
    position: relative;
    padding-top: 2rem;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }

  #missionleft2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 5rem;
    color: #162857;
  }

  #missionright2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2.2rem;
    color: #5a5a5a;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding-top: 2rem;
  }

  .features {
    display: none;
  }

  .features2 {
    display: block;
    background-color: #162857;
    width: 100%;
    height: 1225px;
    text-align: center;
  }

  .featurestitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 5rem;
    color: #ffffff;
    background-color: #162857;
    width: 100%;
    padding-top: 9rem;
  }

  #featuresleft2 {
    padding-top: 60px;
  }

  .cards2 {
    /* width: 30rem; */
    width: 48rem;
    height: 45rem;
    border-radius: 1rem;
    background-color: white;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  #featuresright2 {
    padding-top: 70px;
  }

  #gradecardimage2 {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-left: 6px;
  }

  #gradeimage2 {
    width: 360px;
    height: auto;
  }

  #gradecardtitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2.9rem;
    color: #5a5a5a;
  }

  #gradecarddescription2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.66rem;
    color: #5a5a5a;
    padding-top: 0.7rem;
    padding-left: 3rem;
    padding-right: 3rem;
    line-height: 2.6rem;
  }

  #enrollmentcardimage2 {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-left: 6px;
  }

  #enrollmentimage2 {
    width: 375px;
    height: auto;
  }

  #enrollmentcardtitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2.9rem;
    color: #5a5a5a;
  }

  #enrollmentcarddescription2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.66rem;
    color: #5a5a5a;
    padding-top: 0.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    line-height: 2.6rem;
  }

  .footer {
    display: none;
  }

  .footer2 {
    display: block;
    width: 100%;
    height: 125px;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.5rem;
    color: #4f7094;
    text-align: center;
    padding-top: 50px;
  }

  .footertext2 {
    color: #4f7094;
  }

  .footerCatalog2 {
    padding-right: 4rem;
  }

  .footerGrades2 {
    padding-right: 4rem;
  }

  .footerEnrollment2 {
    padding-right: 4rem;
  }

  .footerAbout2 {
  }
}

@media screen and (max-width: 725px) {
  .intro {
    font-family: "ProximaNova", "Helvetica Neue";
    background-image: url("/src/images/home-bg.png");
    /* height: 100%;  */
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    /* Center and scale the image nicely */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: block;
    object-fit: cover;
    margin: 0 auto;
    margin-top: -20px;
  }

  .introtext {
    position: relative;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }

  .title {
    margin: 10;
  }

  .Aggie {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 4.2rem;
    color: #162857;
    margin: 0;
  }
  .Explorer {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 4.2rem;
    color: #eab517;
    margin: 0;
  }
  .Description {
    font-family: "ProximaNova", "Helvetica Neue";
    padding-top: 0.8rem;
    font-size: 2rem;
    color: #eab517;
    margin: 20;
  }
  .button {
    padding: 2rem;
    outline-color: #162857;
  }
  a#homebutton {
    font-family: "ProximaNova", "Helvetica Neue";
    background-color: #162857;
    font-size: 1.5rem;
    color: #ffffff;
    outline-color: #162857;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 3rem;
    padding-left: 3rem;
    border-radius: 1rem;
  }

  .introarrow {
    position: absolute;
    width: 100%;
    bottom: 60px;
    text-align: center;
  }
  .whatwedo {
    /*margin-top: 60rem;*/
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.5rem;
    color: #162857;
    text-align: center;
    margin: 0;
  }

  .arrow {
    display: inline-block;
    color: #162857;
    width: 1rem;
    height: 1rem;
    border: 2.5px solid transparent;
    border-right-color: #162857;
    border-bottom-color: #162857;
    transform: rotate(45deg);
  }
  .mission {
    width: 100%;
    height: 300px;
  }
  #missionleft {
    display: none;
  }

  #missionright {
    display: none;
  }

  #mission2 {
    display: block;
    position: relative;
    padding-top: 2rem;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
  }

  #missionleft2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 5rem;
    color: #162857;
  }

  #missionright2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2.2rem;
    color: #5a5a5a;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    padding-top: 2rem;
  }

  .features {
    display: none;
  }

  .features2 {
    display: block;
    background-color: #162857;
    width: 100%;
    height: 1225px;
    text-align: center;
  }

  .featurestitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 5rem;
    color: #ffffff;
    background-color: #162857;
    width: 100%;
    padding-top: 9rem;
  }

  #featuresleft2 {
    padding-top: 60px;
  }

  .cards2 {
    width: 30rem;
    height: 40rem;
    border-radius: 1rem;
    background-color: white;
  }

  #featuresright2 {
    padding-top: 70px;
  }

  #gradecardimage2 {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-left: 6px;
  }

  #gradeimage2 {
    width: 260px;
    height: auto;
  }

  #gradecardtitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    color: #5a5a5a;
  }

  #gradecarddescription2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: #5a5a5a;
    padding-top: 0.7rem;
    padding-left: 3rem;
    padding-right: 3rem;
    line-height: 2.6rem;
  }

  #enrollmentcardimage2 {
    text-align: center;
    padding-top: 35px;
    padding-bottom: 20px;
    padding-left: 6px;
  }

  #enrollmentimage2 {
    width: 262px;
    height: auto;
  }

  #enrollmentcardtitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    color: #5a5a5a;
  }

  #enrollmentcarddescription2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: #5a5a5a;
    padding-top: 0.8rem;
    padding-left: 3rem;
    padding-right: 3rem;
    line-height: 2.6rem;
  }

  .footer {
    display: none;
  }

  .footer2 {
    display: block;
    width: 100%;
    height: 125px;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.5rem;
    color: #4f7094;
    text-align: center;
    padding-top: 50px;
  }

  .footertext2 {
    color: #4f7094;
  }

  .footerCatalog2 {
    padding-right: 4rem;
  }

  .footerGrades2 {
    padding-right: 4rem;
  }

  .footerEnrollment2 {
    padding-right: 4rem;
  }

  .footerAbout2 {
  }
}

@media screen and (max-width: 500px) {
  .Description {
    font-family: "ProximaNova", "Helvetica Neue";
    padding-top: 0.8rem;
    font-size: 2.15rem;
    color: #eab517;
    margin: 20;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 375px) {
  #featuresleft2 {
    padding-top: 60px;
    padding-left: 0px;
  }

  .cards2 {
    width: 30rem;
    height: 40rem;
    border-radius: 1rem;
    background-color: white;
  }

  #featuresright2 {
    padding-top: 70px;
    padding-left: 0px;
  }
}
