@font-face {
  font-family: ProximaNova;
  src: url("/src/fonts/Proxima_Nova_reg.ttf") format("truetype");
}

@font-face {
  font-family: ProximaNova-Bold;
  src: url("/src/fonts/Proxima_Nova_Bold.ttf") format("truetype");
}

.About2 {
  display: none;
}

#AboutOurTeam {
  width: 100%;
  height: 650px;
}

#group {
  width: 600px;
  height: auto;
  padding-top: 75px;
  margin-left: 50px;
}

#aboutimage {
  text-align: center;
  top: 18%;
  margin-left: -20px;
}

#aboutdescription {
  top: 27%;
  margin-left: -45px;
}

#abouttitle {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 3.5rem;
  color: #5a5a5a;
}

#aboutdescrip {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.9rem;
  color: #5a5a5a;
  padding-top: 20px;
  padding-right: 10px;
  line-height: 3.5rem;
}

.imageClick {
  cursor: pointer;
}

.members {
  background-color: #162857;
  width: 100%;
  height: 800px;
}
.members2 {
  display: none;
}

.memberstitle {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 4.5rem;
  color: #ffffff;
  background-color: #162857;
  width: 100%;
  padding-left: 85px;
  padding-top: 70px;
  padding-bottom: 20px;
}

#pooja {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#rizk {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#sreeram {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#robert {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#andrew {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#giovanna {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#derek {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#james {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#omid {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#gennifer {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#minhtu {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#ke {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#kiran {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#john {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#salma {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#elizabeth {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}

#prabhdeep {
  width: 170px;
  height: auto;
  border-radius: 0.5rem;
}
.name {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 1.6rem;
  color: white;
  padding-top: 8px;
}

.position {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.5rem;
  color: white;
  padding-top: 1px;
}

.footer {
  width: 100%;
  height: 125px;
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 2rem;
  color: #162857;
  text-align: center;
  padding-top: 55px;
}

.footer2 {
  display: none;
}

.footertext {
}

.footerCatalog {
  padding-right: 20rem;
}

.footerGrades {
  padding-right: 20rem;
}
.footerEnrollment {
  padding-right: 20rem;
}

.footerAbout {
}

@media screen and (max-width: 1200px) {
  .About {
    display: none;
  }

  .About2 {
    display: block;
  }

  #AboutOurTeam2 {
    width: 100%;
    height: 800px;
    text-align: center;
    padding-left: 28px;
  }

  #group2 {
    width: 600px;
    height: auto;
  }

  #aboutimage2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10rem;
    width: 100%;
  }

  #aboutdescription2 {
    margin-top: -40px;
    padding-left: 60px;
    padding-right: 60px;
  }

  #abouttitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 4rem;
    color: #5a5a5a;
  }

  #aboutdescrip2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.8rem;
    color: #5a5a5a;
    padding-top: 20px;
    line-height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .members {
    display: none;
  }

  .members2 {
    display: inline-block;
    background-color: #162857;
    width: 100%;
    height: 1350px;
    text-align: center;
  }

  .memberstitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 4rem;
    color: #ffffff;
    background-color: #162857;
    width: 100%;
    padding-top: 100px;
    padding-bottom: 40px;
  }

  #image2 {
    width: 135px;
    height: auto;
    border-radius: 0.5rem;
    margin-right: -1.7rem;
  }

  #image2r {
    width: 135px;
    height: auto;
    border-radius: 0.5rem;
    margin-left: -1.7rem;
  }

  .name2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 1.4rem;
    color: white;
    padding-top: 8px;
    text-align: center;
    padding-left: 20px;
  }

  .position2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    color: white;
    padding-top: 1px;
    padding-bottom: 30px;
    text-align: center;
    padding-left: 20px;
  }

  .name2r {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 1.4rem;
    color: white;
    padding-top: 8px;
    text-align: center;
    padding-right: 8px;
  }

  .position2r {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    color: white;
    padding-top: 1px;
    padding-bottom: 30px;
    text-align: center;
    padding-right: 8px;
  }

  .footer {
    display: none;
  }

  .footer2 {
    display: block;
    width: 100%;
    height: 150px;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.5rem;
    color: #4f7094;
    text-align: center;
    padding-top: 50px;
  }

  .footertext2 {
    color: #4f7094;
  }

  .footerCatalog2 {
    padding-right: 4rem;
  }

  .footerGrades2 {
    padding-right: 4rem;
  }

  .footerEnrollment2 {
    padding-right: 4rem;
  }

  .footerAbout2 {
  }
}

@media screen and (max-width: 675px) {
  #group2 {
    width: 350px;
    height: auto;
  }
  #aboutimage2 {
    padding-top: 10rem;
  }
  #aboutdescription2 {
    margin-top: -40px;
    padding-left: 0px;
    padding-right: 0px;
  }
  #aboutdescrip2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    color: #5a5a5a;
    padding-top: 20px;
    line-height: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
