@font-face {
  font-family: "Proxima Nova";
  src: url("/src/fonts/Proxima Nova Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova Bold";
  src: url("/src/fonts/Proxima Nova Bold.ttf") format("truetype");
}

#dashContent {
  overflow-y: hidden;
  position: absolute;
  /* offset top for navbar */
  top: 70px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: stretch;
}

#leftNav {
  background: rgb(235, 243, 243);
  background: linear-gradient(90deg, rgba(235, 243, 243, 1) 0%, rgba(213, 235, 235, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 6vh 3vw 6vh 3vw;
}
/* There are 2 sub divs 
    which hold top nav content
    and footer nav content
*/
#leftNav > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Style the actual links themselves*/
.navButton {
  font-family: "Proxima Nova", "Helvetica Neue";
  font-size: 16px;
  color: #162857;
  margin-top: 1em;
  width: 10em;
  height: 2.5em;
  border-width: 0;
  border-radius: 25px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.is-active {
  font-family: "Proxima Nova Bold", "Helvetica Bold";
  border-width: 0;
  border-radius: 25px;
  background-color: #ffc519;
}

#rightContent {
  width: 100%;
  padding: 6.5rem;
}
