#card {
  display: flex;
  width: 100%;
  height: 45rem;
  justify-content: center;
  padding: 50px 0 50px 0;
  border-radius: 2rem;
  background-color: white;
}

#cardImage {
  width: auto;
  height: 100%;
}

#featuresContainer {
  width: 80wv;
  padding-top: 7rem;
  padding-bottom: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#featurestitle {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  align-self: flex-start;
  font-size: 40px;
  margin-bottom: 1em;
  color: #033266;
}
