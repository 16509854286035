@font-face {
  font-family: Proxima Nova;
  src: url("/src/fonts/Proxima Nova Regular.ttf") format("truetype");
}

@font-face {
  font-family: Proxima Nova Bold;
  src: url("/src/fonts/Proxima Nova Bold.ttf") format("truetype");
}

#navbar {
  position: absolute;
  font-family: "Proxima Nova", "Helvetica Neue";
  font-size: 1.5rem;
  background-color: #cecece40;
  overflow: auto;
  width: 100%;
  height: 70px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
}

#navbartitle {
  font-family: "Proxima Nova Bold";
  color: #162857;
  font-size: 2rem;
}

#responsive-navbar-nav {
  justify-content: center;
}

.navlinksspace {
  position: relative;
  right: 25px;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.5em 1em 0.5em 1em;
  color: #000000;
}

.navlinksspace:hover {
  color: white;
  background-color: #ffc519;
  border: none;
  border-radius: 2em;
}

#signInButton {
  font-family: "Proxima Nova";
  font-size: 14px;
  background-color: #162857;
  color: white;
  border: none;
  width: 90px;
  height: 30px;
  border-radius: 0.5em;
}

#signInButton:hover {
  background-color: transparent;
  border-style: solid;
  border-width: 1px;
}

@media screen and (max-width: 768px) {
  #navlinksspace {
    padding-left: 0rem;
    color: #000000;
  }
  #navlinks {
    flex-direction: row;
    justify-content: space-evenly;
    font-family: "Proxima Nova", "Helvetica Neue";
    padding-left: 2rem;
    margin: 0;
  }
}

@media screen and (max-width: 480px) {
  #navlinks {
    flex-direction: column;
    /* align-items: flex-end; */
  }
}
