@font-face {
  font-family: ProximaNova;
  src: url("/src/fonts/Proxima Nova Regular.ttf") format("truetype");
  font-family: ProximaNova-Bold;
  src: url("/src/fonts/Proxima Nova Bold.ttf") format("truetype");
}

html {
  scroll-behavior: smooth;
  font-size: 10px;
}

#smallscreen {
  display: none;
}

#mediumscreen {
  display: none;
}

#classid {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 1.8rem;
}

#classname {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.3rem;
}

#classinstructor {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.3rem;
}

#classtablethang {
  margin-left: 1rem;
  margin-right: -1rem;
}

#classtablethang2 {
  margin-left: 1rem;
}

#searchclassbar {
  height: 3rem;
  border-radius: 0.5rem;
}

#filtertitle {
  size: large;
  text-align: center;
}

#Course_title {
  size: large;
  text-align: center;
}

.page {
  top: 70px;
  width: 100%;
  overflow-x: hidden;
  background-color: white;
}

#details_right {
  padding-bottom: 20px;
}

#splitleft {
  padding-right: 2rem;
}

#splitright {
  padding-top: 6rem;
}

#filterbutton {
  background-color: transparent; /* Green */
  border: none;
}

#filter_right {
  display: none;
  margin-left: 10px;
  float: center;
}

#addgap {
  margin-left: 6rem;
}

.filtertitle {
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  font-size: 3rem;
  padding-bottom: 3rem;
}

#quartertitle {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
}

#quarterboxes {
  padding-top: 1rem;
}

#quar {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.5rem;
  padding-right: 2.8rem;
}
#checktext {
  padding-right: 0.8rem;
}

#searchbar {
  height: 4rem;
}

#title {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
  padding-top: 1.8rem;
  padding-bottom: 0.5rem;
}

#dropdown-thing {
  z-index: 500;
  position: absolute;
}

#second-thing {
  background-color: #ffffff;
  border-style: solid;
  border-width: 1px;
  border-color: #dddcdc;
  font-family: "ProximaNova", "Helvetica Neue";
  color: #162857;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
  float: right;
  margin-left: 5.4rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}

#corebox {
  border: 1px solid #bebebe;
  padding: 2rem;
}

#extrafeatures {
  display: none;
}

.coretitle {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
  padding-top: 1.8rem;
  padding-bottom: 0.5rem;
}

#core {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
  padding-right: 2.8rem;
}

#coretext {
  padding-left: 2rem;
}

.getitle {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
  padding-top: 1.8rem;
  padding-bottom: 0.5rem;
}

#gebox {
  border: 1px solid #bebebe;
  padding: 2rem;
}

#ge {
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 1.8rem;
  padding-right: 2.8rem;
}

#getext {
  padding-left: 2rem;
}

#AdvancedButton {
  margin-top: 3rem;
  background-color: transparent; /* Green */
  border: none;
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  color: #162857;
  font-size: 1.6rem;
  margin-left: -0.5rem;
  margin-bottom: 6rem;
  display: inline;
}

#AdvancedButton2 {
  display: none;
  margin-top: 3rem;
  background-color: transparent; /* Green */
  border: none;
  font-family: "ProximaNova-Bold", "Helvetica Neue";
  color: #162857;
  font-size: 1.6rem;
  margin-left: -0.5rem;
  margin-bottom: 6rem;
}

#GoButton {
  margin-top: 3rem;
  color: #ffffff;
  background-color: #162857;
  outline-color: #162857;
  font-size: 1.6rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 4rem;
  padding-left: 4rem;
  border-radius: 1rem;
  display: inline;
  float: right;
}

.card {
  font-family: "ProximaNova", "Helvetica Neue";
  height: auto;
  width: auto;
  border: none;
  margin-left: 8%;
}

.left_side {
  width: 100%;
  height: auto;
}
.unit-right {
  margin-top: 25px;
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 12px;
}

.left-content {
  overflow: auto;
}

h4 {
  font-weight: lighter;
}
.right-content {
  height: 100vh;
  border-left: 1px solid #bebebe;
}
.top-border {
  border-top: 1px solid #bebebe;
}

.right-text {
  font-family: "ProximaNova", "Helvetica Neue";
  color: #5a5a5a;
  font-size: 1.4rem;
  text-align: center;
  padding-top: 20%;
}

.line-1 {
  margin-bottom: 3px;
}

.line-2 {
  margin-top: -5px;
}

#catalog_cow {
  height: 125px;
  width: auto;
}

.footer {
  width: 100%;
  height: 125px;
  font-family: "ProximaNova", "Helvetica Neue";
  font-size: 2rem;
  color: #162857;
  text-align: center;
  padding-top: 55px;
}

.right-content {
  height: 100vh;
  border-left: 1px solid #bebebe;
}

.footertext {
  color: #162857;
}

.footerCatalog {
  padding-right: 20rem;
}

.footerGrades {
  padding-right: 20rem;
}

.footerEnrollment {
  padding-right: 20rem;
}

@media screen and (max-width: 376px) {
  #normalscreen {
    display: none;
  }

  #mediumscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: 360px;
    height: 850px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 360px;
    margin-left: 2.5rem;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    height: 3rem;
    border-radius: 0.5rem;
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #buttonrow {
    justify-content: center;
  }

  #prevbutton {
    background-color: #162857;
    width: 40px;
    height: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1rem;
    color: white;
  }
  #nextbutton {
    background-color: #162857;
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1rem;
    color: white;
  }

  #modelgeneral {
    margin-top: 50px;
    margin-left: 12px;
    margin-right: 10px;
    width: 350px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 500px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 4.5rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 5.7rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 3.8rem;
    padding-right: 3.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) and (min-width: 650px) {
  #normalscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: auto;
    height: 1500px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 600px;
    margin-left: 6rem;
    justify-content: center;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    margin-top: 0.6rem;
    margin-bottom: 2rem;
    height: 4rem;
    border-radius: 0.5rem;
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #buttonrow {
    justify-content: center;
  }

  #prevbutton {
    background-color: #162857;
    width: 40px;
    height: 40px;
    margin-left: 10rem;
    margin-right: 6rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #nextbutton {
    background-color: #162857;
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #modaldetail {
    margin-top: 100px;
    width: 700px;
  }

  #modelgeneral {
    margin-top: 100px;
    width: 700px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 500px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 9rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 10rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 4rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3.5rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.7rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 650px) and (min-width: 560px) {
  #normalscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: auto;
    height: 1500px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 500px;
    margin-left: 6rem;
    justify-content: center;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    margin-top: 0.6rem;
    margin-bottom: 2rem;
    height: 4rem;
    border-radius: 0.5rem;
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #buttonrow {
    justify-content: center;
  }

  #prevbutton {
    background-color: #162857;
    width: 40px;
    height: 40px;
    margin-left: 10rem;
    margin-right: 6rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #nextbutton {
    background-color: #162857;
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #modelgeneral {
    margin-top: 100px;
    width: 500px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 400px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 9rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 10rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 4rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3.5rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.7rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 560px) and (min-width: 411px) {
  #normalscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: auto;
    height: 1500px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 370px;
    margin-left: 6rem;
    justify-content: center;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    margin-top: 0.6rem;
    margin-bottom: 2rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #buttonrow {
    justify-content: center;
  }

  #prevbutton {
    background-color: #162857;
    width: 40px;
    height: 40px;
    margin-right: 6rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #nextbutton {
    background-color: #162857;
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #modelgeneral {
    margin-top: 80px;
    width: 370px;
    margin-left: 25px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 350px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 4rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 5.5rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 3rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3.5rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.7rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 411px) and (min-width: 376px) {
  #normalscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: auto;
    height: 1500px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 360px;
    margin-left: 3rem;
    justify-content: center;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    margin-top: 0.6rem;
    margin-bottom: 2rem;
    height: 3.5rem;
    border-radius: 0.5rem;
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #buttonrow {
    justify-content: center;
  }

  #prevbutton {
    background-color: #162857;
    width: 40px;
    height: 40px;
    margin-right: 6rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #nextbutton {
    background-color: #162857;
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    color: white;
  }

  #modelgeneral {
    margin-top: 80px;
    width: 330px;
    margin-left: 25px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 350px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 3rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 3.8rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    font-size: 1.4rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3.5rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 0.7rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.4rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 376px) {
  #normalscreen {
    display: none;
  }

  #mediumscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: 360px;
    height: 850px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 360px;
    margin-left: 2.5rem;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    height: 3rem;
    border-radius: 0.5rem;
  }
  #buttonrow {
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #prevbutton {
    width: 40px;
    height: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1rem;
    color: white;
  }
  #nextbutton {
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1rem;
    color: white;
  }

  #modelgeneral {
    margin-top: 50px;
    margin-left: 25px;
    margin-right: 10px;
    width: 320px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 400px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 4.5rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 5.7rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 3.8rem;
    padding-right: 3.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 375px) and (min-width: 320px) {
  #normalscreen {
    display: none;
  }

  #mediumscreen {
    display: none;
  }

  #smallscreen {
    display: inline-block;
    width: 300px;
    height: 600px;
  }

  #splitleft2 {
  }

  #classtablethang3 {
    width: 300px;
    margin-left: 2.5rem;
  }

  #classtablethang4 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  #searchclassbar2 {
    height: 3rem;
    border-radius: 0.5rem;
  }

  #classchangebutton {
    margin-top: 30px;
    margin-left: 140px;
    margin-bottom: 2rem;
    text-align: center;
  }

  #prevbutton {
    width: 40px;
    height: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1rem;
    color: white;
  }
  #nextbutton {
    height: 40px;
    width: 40px;
    margin-right: 2rem;
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1rem;
    color: white;
  }

  #modelgeneral {
    margin-top: 50px;
    margin-left: 16px;
    margin-right: 10px;
    width: 290px;
  }

  #filtertitle2 {
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    font-size: 2rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .modal-dialog {
    overflow-y: initial !important;
  }

  .modal-body {
    height: 400px;
    overflow-y: auto;
    margin-left: 12px;
    margin-right: 12px;
  }

  #quartertitle2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #quarterinbox {
    border: 1px solid #dddcdc;
    padding: 1rem;
    margin-bottom: 2rem;
  }

  #quarterboxes2 {
    padding-top: 1rem;
    padding-left: 1.2rem;
  }

  #quarterboxes3 {
    padding-top: 0.7rem;
    padding-left: 2.3rem;
  }

  #quar2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2rem;
  }

  #checktext2 {
    padding-right: 0.8rem;
  }

  #dropdown-thing2 {
    margin-bottom: 1.5rem;
  }

  #second-thing2 {
    background-color: #ffffff;
    border-style: solid;
    border-width: 1px;
    border-color: #dddcdc;
    font-family: "ProximaNova", "Helvetica Neue";
    color: #162857;
    padding-left: 3.8rem;
    padding-right: 3.8rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    font-size: 1.2rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  #searchbar2 {
    height: 3rem;
  }

  #title2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.6rem;
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }

  #extrafeatures2 {
    display: none;
  }

  #corebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #core2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2.4rem;
  }

  #coretext2 {
    padding-left: 1.5rem;
  }

  #gebox2 {
    border: 1px solid #dddcdc;
    padding: 2rem;
    margin-bottom: 0.5rem;
  }

  #ge2 {
    font-family: "ProximaNova", "Helvetica Neue";
    font-size: 1.2rem;
    padding-right: 2.4rem;
  }

  #getext2 {
    padding-left: 1.5rem;
  }

  #AdvancedButton3 {
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
    display: inline;
  }

  #AdvancedButton4 {
    display: none;
    margin-top: 3rem;
    background-color: transparent; /* Green */
    border: none;
    font-family: "ProximaNova-Bold", "Helvetica Neue";
    color: #162857;
    font-size: 1.6rem;
    margin-left: -0.5rem;
    margin-bottom: 3rem;
  }
}
