@font-face {
  font-family: "Proxima Nova Bold";
  src: url("/src/fonts/Proxima Nova Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Proxima Nova";
  src: url("/src/fonts/Proxima Nova Regular.ttf") format("truetype");
}

#btnGrid {
  justify-content: center;
  margin-top: 5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row: auto auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

#btnName {
  font-size: 30px;
  font-family: "Proxima Nova Bold", "Helvetica";
  color: #162857;
}

#btnDesc {
  font-size: 18px;
  font-family: "Proxima Nova", "Helvetica";
  margin-top: 1rem;
  line-height: 3rem;
  color: #162857;
}

.featureBtn {
  background-color: #ebf8f8;
  border-width: 0;
  border-radius: 25px;
  padding: 4rem;
  width: 100%;
  text-align: left;
}

.active {
  background-color: #c7f1f1;
}
