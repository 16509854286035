@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Proxima Nova";
    src: url("/src/fonts/Proxima Nova Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Proxima Nova Bold";
    src: url("/src/fonts/Proxima Nova Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "Proxima Nova Semibold";
    src: url("/src/fonts/Proxima Nova Semibold.otf") format("opentype");
  }
}
